<template>
  <div class="dzs-operation-limit">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Limites do Usuário</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <base-input :disabled="formDisabled"
                          label="Usuário"
                          type="text"
                          v-model="username">
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Tipo de Pessoa">
                <el-select :disabled="formDisabled"
                           placeholder="Selecionar"
                           size="large"
                           v-model="personType">
                  <el-option class="select-success"
                             label="Pessoa Física"
                             value="NATURAL_PERSON"/>
                  <el-option class="select-info"
                             label="Pessoa Jurídica"
                             value="LEGAL_PERSON"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-4">
              <base-input :disabled="formDisabled"
                          :label="personType=='NATURAL_PERSON'?'CPF':'CNPJ'"
                          name="CPF/CNPJ"
                          type="tel"
                          v-mask="['###.###.###-##','##.###.###/####-##']"
                          v-model="socialNumber">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <base-input :disabled="formDisabled"
                          label="Nome Completo"
                          name="Nome Completo"
                          type="text"
                          v-model="personName">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div v-if="personType == 'LEGAL_PERSON'">-->
              <div class="col-md-12">
                <base-input :disabled="formDisabled"
                            label="Razão Social"
                            name="Razão Social"
                            type="text"
                            v-model="companyName">
                </base-input>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table :data="tableData"
                        :row-class-name="customLimitClassStyle"
                        class="table-striped"
                        style="width: 85%">
                <el-table-column :min-width="60" label="Limite">
                  <template slot-scope="props">
                    <span>
                      <span>
                        <b>{{props.row.limit}}</b>
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="25" label="Valor">
                  <template slot-scope="props">
                    <span>
                      <base-input v-model="props.row.value" v-on:change="handleChangeValue(props)"
                                  v-on:input="handleChangeValue(props)"/>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Tipo Op.">
                  <template slot-scope="props">
                    <span>
                      <span>{{opStatusToLabel(props.row.operationNature)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :width="120" class-name="td-actions" fixed="right" label="Ação">
                  <template slot-scope="props">
                  <span style="margin-left: 5px"
                        v-if="getEditedState(props.$index) && hasAuthorization('BO_SET_USER_OP_LIMIT')">
                    <button @click="save(props)" title="Salvar" type="button" class="btn btn-sm btn-danger">
                      <i class="fas fa-edit"></i>
                    </button>
                  </span>
                    <span style="margin-left: 5px"
                          v-if="props.row.customLimit && hasAuthorization('BO_DELETE_USER_OP_LIMIT')">
                      <button @click="remove(props)" title="Restaurar Limite Padrão" class="btn btn-sm btn-warning">
                        <i class="fas fa-unlink"></i>
                      </button>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <i class="fas fa-circle" :style="'color: yellow'"></i>
              <span>Limites personalizados</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from '../../../components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "../../../ws.service"
  import {isAuthorized} from "../../../util/role.utils";

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Select);
  Vue.use(Option);
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        tableData: [],
        editStatus: [],
        username: null,
        personName: null,
        socialNumber: null,
        companyName: null,
        personType: null,
        formDisabled: false
      }
    },
    created() {
      this.username = this.$route.params.username
      this.loadData()
    },
    methods: {
      customLimitClassStyle({row, index}) {
        console.debug(row)
        return row.customLimit !== null && row.customLimit ? 'table-warning' : ''
      },
      getEditedState(index) {
        return this.editStatus[index].edited
      },
      handleChangeValue(props) {
        this.editStatus[props.$index].edited = true;
      },
      loadData() {
        postWs("/op-limit/list-user-op-limits",
          true,
          {'username': this.username},
          null,
          this.successLoadData,
          failWs);
      },
      successLoadData(response) {
        this.tableData = response.data.list;
        this.editStatus = this.tableData.map(row => {
          return {edited: false}
        })

        this.personName = response.data.personName;
        this.socialNumber = response.data.socialNumber;
        this.companyName = response.data.companyName;
        this.personType = response.data.personType;
        this.formDisabled = true
      },
      save(operationLimitScope) {
        this.$swal({
          title: 'Alterar Limite',
          text: "Você deseja realmente salvar as alterações para o limite " + operationLimitScope.row.limit + "?",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          cancelButtonText: 'CANCELAR'
        }).then((result) => {
          if (result.isConfirmed) {
            postWs("/op-limit/set-op-user-limit", true,
              null, {
                username: this.username,
                limit: operationLimitScope.row.limit,
                value: operationLimitScope.row.value
              },
              (response) => {
                this.editStatus[operationLimitScope.$index].edited = false
                operationLimitScope.row.customLimit = true
              }, failWs)
          }
        })
      },
      remove(operationLimitScope) {
        this.$swal({
          title: 'Restaurar Limite Padrão',
          text: "Você deseja realmente restaurar o limite " + operationLimitScope.row.limit + " para o valor padrão?",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          cancelButtonText: 'CANCELAR'
        }).then((result) => {
          if (result.isConfirmed) {
            postWs("/op-limit/delete-op-user-limit", true,
              null, {
                username: this.username,
                limit: operationLimitScope.row.limit,
                value: null
              },
              (response) => {
                this.loadData()
              }, failWs)
          }
        })
      },
      opStatusToLabel(opType) {
        if (opType === 'SOLICITATION') {
          return 'SOLICITAÇÃO'
        } else if (opType === 'OUT') {
          return 'SAÍDA'
        }
        return '??'
      },
      hasAuthorization(role) {
        return isAuthorized(role);
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 12px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

</style>
